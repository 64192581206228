import React from "react"
import Layout from "../components/layout"

const ContactPage = () => {
  return (
    <Layout>
      <h2>Contacto</h2>
      <p>Puede escribirme a: antidotclick ((@)) gmail.com</p>
    </Layout>
  )
}

export default ContactPage
